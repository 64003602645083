import React from 'react';
import Layout from '../../../components/Layout';

import CompanyCreateEdit from "../../../components/companies/CompanyCreateEdit";

export default function CompanyEditPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <CompanyCreateEdit variant="edit" style={{minWidth:700, width:"50%", marginLeft: "auto", marginRight: "auto"}} id={props.params.companyId}/>
        </Layout>
    )
}